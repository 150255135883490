div.carousel {
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    margin: 10px;
  }
  
  div.carousel div {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }
  
  .imags {
    width: 30rem;
    height: 19rem;
  }
  ::-webkit-scrollbar-thumb:horizontal{
    background: limegreen;
    padding: 10px;
    border-radius: 1px;
}
::-webkit-scrollbar-thumb:vertical{
  background-color: limegreen;
}