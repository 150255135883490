
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 1200px;
    position: relative;
  }
  
  .modal-image {
    width: 100%;
    height: auto;
  }
  
  .close {
    color: #fff8f8;
    float: right;
    font-size: 5rem;
    font-weight: bold;
    position: absolute;
    top: 0px;
    right: 25px;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }