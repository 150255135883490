@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(255, 255, 255); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(126, 219, 55); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(76, 161, 23); 
  }
